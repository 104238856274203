

export default [
  
  {
    path: "/",
    name: "Index",
    component: () =>
      /* webpackChunkName: "view-[request]" */ import("@/views/index.vue"),
  },
];
