<template>
  <div>
    
    <div  v-if="isMobile ">
      
      <router-view />
    </div>

    <div v-else>
      Mobile Only
    </div>
    <!-- <div class="bgbottom"></div> -->
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
export default{
  data(){
    return{
      isMobile
    }
  }
}
</script>

<style>
@font-face {
  font-family: "Gotham";
  src: url("assets/css/newfont/Gotham-Black.woff2") format("woff2"),
    url("assets/css/newfont/Gotham-Black.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}




/* @font-face {
  font-family: 'DIN Next LT Pro';
  src: url('assets/css/fonts/DINNextLTPro-Bold.eot');
  src: url('assets/css/fonts/DINNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/css/fonts/DINNextLTPro-Bold.woff2') format('woff2'),
    url('assets/css/fonts/DINNextLTPro-Bold.woff') format('woff'),
    url('assets/css/fonts/DINNextLTPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
} */

body {
  font-family: Gotham !important;
  font-weight: 500;
  color: #000 !important;
}

p {
  color: #000;
}

#app {
  font-family: Gotham;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
}
</style>
