import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Urls from "@/utils/url.js";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import QrcodeVue from "qrcode.vue";
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
// import VueGtag from "vue-gtag";

Vue.config.productionTip = true;
Vue.use(Antd);
Vue.mixin(Urls);
Vue.use(CKEditor);
Vue.use(QrcodeVue);

Vue.use(
  new VueSocketIO({
    debug: false,
        connection: new SocketIO(window.location.origin , {
      
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 500,
      maxReconnectionAttempts: Infinity,
      query: { TOKEN: "UNITY_WEB" },
    }),

    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
    options: {}, //Optional options
  })
);

// Vue.use(VueGtag, {
//   config: { id: "G-4RQT0MK1SH" }
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
